<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-columns">
        <!-- Info column -->
        <div class="column">
          <BaseText class="bold">{{ $t("footer.info") }}</BaseText>
          <NuxtLink
            v-for="page in pagesData?.websitePages ?? []"
            :key="page.path"
            :to="localePath(page.path)"
          >
            {{ page.title }}
          </NuxtLink>
        </div>

        <!-- Categories column -->
        <div class="column hide-small">
          <BaseText class="bold">{{ $t("footer.categories") }}</BaseText>
          <NuxtLink
            v-for="item in categoriesData?.categories ?? []"
            :key="item?.id"
            :to="localePath({ name: 'categories-slug', params: { slug: item!.slug } })"
          >
            {{ item!.name }}
          </NuxtLink>
        </div>

        <!-- Popular column -->
        <div class="column hide-small">
          <BaseText class="bold">{{ $t("footer.popular") }}</BaseText>
          <NuxtLink
            v-for="item in popularShops[host]"
            :key="item.title"
            :to="
              localePath({
                name: 'slug-shops',
                params: {
                  slug: isProductionEnv ? item.slug : item.slugStaging,
                },
              })
            "
          >
            {{ $t("footer.brochure", {shop: item.title}) }}
          </NuxtLink>
        </div>

        <!-- Socials column -->
        <div class="column">
          <BaseText class="bold">{{ $t("footer.socials") }}</BaseText>
          <a
            v-for="item in socials[host]"
            :key="item.name"
            :href="item.url"
            target="_blank"
          >
            {{ item.name }}
          </a>
        </div>

        <!-- Logo & buttons column -->
        <div class="column">
          <NavbarLogo class="logo dark" />
          <AppStoreButtons class="gold" />
          <ul class="unstyled">
            <li>{{ $t("footer.save-brochures") }}</li>
            <li>{{ $t("footer.brochures-near-you") }}</li>
            <li>{{ $t("footer.lots-of-downloads") }}</li>
          </ul>
        </div>
      </div>

      <div class="footer-bottom mt-4">
        <BaseText>{{ $t(`footer.copyright-${host}`) }}</BaseText>
        <div class="footer-bottom-links">
          <a :href="localePath('/privacy-statement')">
            {{ $t("footer.privacy") }}
          </a>
          <a :href="localePath('/disclaimer')">
            {{ $t("footer.disclaimer") }}
          </a>
          <a :href="localePath('/contact')">
            {{ $t("footer.contact") }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useCategoriesQuery, useWebsitePagesQuery } from "~/graphql/generated";
import { popularShops } from "~/assets/data/popularShops";

const host = useCurrentHost();
const isProductionEnv = getEnvironment() === "production";
const localePath = useLocalePath();
const { data: categoriesData, executeQuery: executeCategoriesQuery } =
  await useCategoriesQuery({});

const { data: pagesData, executeQuery: executePagesQuery } =
  await useWebsitePagesQuery({});

// When the locale changes, refetch the pages and categories with the new locale (in context)
const { locale } = useI18n();
watch(locale, () => {
  executePagesQuery({
    requestPolicy: "network-only",
  });
  executeCategoriesQuery({
    requestPolicy: "network-only",
  });
});

const socials = {
  allefolders: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/AlleFolders",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/allefolders",
    },
    {
      name: "Tiktok",
      url: "https://www.tiktok.com/@allefoldersapp",
    },
    {
      name: "Pinterest",
      url: "https://www.pinterest.com/Alle_Folders/",
    },
    // {
    //   name: "LinkedIn",
    //   url: "https://www.linkedin.com/",
    // },
  ],
  promopromo: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/promopromobe/",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/promopromobe",
    },
    // {
    //   name: "LinkedIn",
    //   url: "https://www.linkedin.com/",
    // },
    // {
    //   name: "Tiktok",
    //   url: "https://www.tiktok.com/",
    // },
    // {
    //   name: "Pinterest",
    //   url: "https://www.pinterest.com/",
    // },
  ],
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $light-95;
  width: 100%;
  padding: 3rem 0 1.5rem 0;
}

.footer-columns {
  display: grid;

  .column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }
}

.logo {
  padding: 0;
}

.footer-bottom {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  .footer-bottom-links {
    display: flex;
    gap: 1rem;
  }
}

// Styles for small screens
@include for-small-screen {
  .footer-columns {
    grid-template-columns: 1fr;
  }
  .footer-bottom {
    flex-direction: column;
  }
  .footer-bottom-links {
    flex-direction: column;
  }
}

// Tablet landscape and up
@include for-medium-screen-up {
  .footer-columns {
    grid-template-columns: 2fr 2fr 2fr 2fr 2.5fr;
  }
}
</style>
